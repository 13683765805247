import { GetDistanceTypeResponse, UpdateDistanceTypePayload } from "@/schemas/responseTypes";
import { apiSlice } from "./apiSlice";
import { TimezoneType } from "@/schemas/tz-schema";

export const settingsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDistanceType: builder.query<GetDistanceTypeResponse, void>({
      query: () => ({
        url: "/settings/distance-type",
        method: "GET",
      }),
      providesTags: ["Settings-distance"],
    }),
    updateDistanceType: builder.mutation<void, UpdateDistanceTypePayload>({
      query: (payload) => ({
        url: "/settings/distance-type",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Settings-distance", "Me"],
    }),
    getTimeZone: builder.query<TimezoneType, void>({
      query: () => ({
        url: "/settings/timezone",
        method: "GET",
      }),
      providesTags: ["Settings-timezone"],
    }),
    setTimezone: builder.mutation<TimezoneType, TimezoneType>({
      query: (body) => ({
        url: "/settings/timezone",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Settings-timezone"],
    }),
  }),
});

export const {
  useGetDistanceTypeQuery,
  useUpdateDistanceTypeMutation,
  useGetTimeZoneQuery,
  useSetTimezoneMutation,
} = settingsApi;
