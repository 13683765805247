import { Suspense } from "react";
import { useRoutes } from "react-router-dom";
import routes from "~react-pages";
import CircularProgress from "@mui/material/CircularProgress";
import { PWAInstallPrompt } from "@/components/PWAInstallPrompt";
import { useGetMeQuery } from "./store/api";
console.log("install event listener added");
addEventListener("install", (event: any) => {
  console.log("install event fired");
  event.addRoutes({
    condition: {
      urlPattern: "/api/*",
    },
    source: "network",
  });
});

function App(): JSX.Element {
  useGetMeQuery();
  return (
    <Suspense
      fallback={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <PWAInstallPrompt />
          <CircularProgress />
        </div>
      }
    >
      {useRoutes(routes)}
    </Suspense>
  );
}

export default App;

