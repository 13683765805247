import React, { useState } from "react";
import Auth from "@/components/auth";
import styles from "./default-layout.module.css";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import PersonIcon from "@mui/icons-material/Map";
import LogoutIcon from "@mui/icons-material/Logout";
import HikingIcon from "@mui/icons-material/Hiking";
import { useNavigate } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import { DecectTimezoneChange } from "@/components/decect-timezone-change";
const routes = ["/", "/routes", "/settings", "/logout"] as const;

const getNumberFromRoute = (route: string): number => {
  const entry = Object.entries(routes)
    .filter(([, v]) => v !== "/")
    .find(([, value]) => {
      console.log({ value, route }, route.startsWith(value));
      return route.startsWith(value);
    });
  return entry ? parseInt(entry[0], 10) : 0;
};

const getRouteFromNumber = (number: number): string => {
  return routes[number] ?? "/";
};
export const DefaultLayout = ({ children }: { children: React.ReactNode }) => {
  const [value, setValue] = useState(getNumberFromRoute(window.location.pathname));
  const navigate = useNavigate();

  const logout = () => {
    window.location.href = "/api/v1/logout";
  };

  const handleNavigationChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    const route = getRouteFromNumber(newValue);
    if (route === "/logout") {
      logout();
      return;
    }
    navigate(route);
  };

  return (
    <Auth>
      <DecectTimezoneChange />
      <div className={styles.app}>
        <div className={styles.content}>{children}</div>
        <BottomNavigation
          value={value}
          onChange={handleNavigationChange}
          showLabels
          className={styles.bottomNavigation}
        >
          <BottomNavigationAction label="Wander" icon={<HikingIcon />} />
          <BottomNavigationAction label="Routes" icon={<PersonIcon />} />
          <BottomNavigationAction label="Settings" icon={<SettingsIcon />} />
          <BottomNavigationAction label="Logout" icon={<LogoutIcon />} />
        </BottomNavigation>
      </div>
    </Auth>
  );
};
