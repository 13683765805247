import { Dashboard } from "@/components/dashboard";
import { useGetMeQuery } from "@/store/api";
import { DefaultLayout } from "@/layouts/default-layout";
import { Box, CircularProgress, Alert } from "@mui/material";
import { Helmet } from "react-helmet";

const HomePage = (): JSX.Element => {
  const { isLoading, error, isError } = useGetMeQuery();

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (isError && error) {
    console.error(error);
  }

  return (
    <DefaultLayout>
      <Helmet>
        <title>NAWAL: Your Progress</title>
        <meta
          name="description"
          content="This is the home screen which shows your progress on the current route."
        />
      </Helmet>
      {isError && error && <Alert severity="error">Unknown Error</Alert>}
      <Dashboard />
    </DefaultLayout>
  );
};

export default HomePage;
