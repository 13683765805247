import React from "react";
import { createRoot } from "react-dom/client";
import "@/styles/global.css";
import App from "@/App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/store";
const el = document.getElementById("root");
if (!el) throw new Error("Root element not found");
const root = createRoot(el);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);
