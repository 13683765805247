import React, { useState } from "react";
import Button from "@mui/material/Button";
import { Box, createTheme, Grid, IconButton, Link, ThemeProvider, useTheme } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useGetMeQuery, useSendEmailLoginMutation } from "@/store/api";
import { ThemeImage } from "./ThemeImage";
import themes from "@/components/routes/themes.json";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Helmet } from "react-helmet";

const theme = themes[Math.floor(Math.random() * themes.length)];

interface AuthProps {
  children: React.ReactNode;
}

export const Auth: React.FC<AuthProps> = ({ children }) => {
  const { data, isLoading: isMeLoading, refetch: refetchMe } = useGetMeQuery();
  const [selectedTheme, setSelectedTheme] = useState(theme);
  const [email, setEmail] = useState<string>(data?.user?.email || "");
  const [error, setError] = useState<string | null>(null);
  const [isEmailLinkSent, setIsEmailLinkSent] = useState<boolean>(false);
  const [isTermsChecked, setIsTermsChecked] = useState<boolean>(false);

  let activeStep = !data?.user?.email ? 0 : 2; // Adjusted for the new step
  if (isTermsChecked) {
    activeStep = 1;
  }

  const [sendEmailLogin, { isLoading: isSendingEmail }] = useSendEmailLoginMutation();

  React.useEffect(() => {
    const onFocus = () => {
      refetchMe();
    };
    window.addEventListener("focus", onFocus);
    return () => {
      window.removeEventListener("focus", onFocus);
    };
  }, [refetchMe]);

  const handleOAuthFlow = async () => {
    try {
      window.location.href = "/api/v1/auth/fitbit";
    } catch (error) {
      console.error("Error during OAuth flow:", error);
    }
  };

  const handleSendLoginLink = async () => {
    setError(null);
    try {
      await sendEmailLogin({ email }).unwrap();
      setIsEmailLinkSent(true);
    } catch (error) {
      setError("Error sending login link. Please try again.");
    }
  };

  if (data?.user?.email && data?.user?.fitbitId) {
    return children;
  }
  const currentTheme = useTheme();
  const customTheme = createTheme({
    palette: {
      primary: {
        main: selectedTheme?.primaryColor || currentTheme.palette.primary.main, // Fallback to current theme if not found
      },
      secondary: {
        main: selectedTheme?.secondaryColor || currentTheme.palette.secondary.main, // Fallback to white if not found
      },
    },
  });

  return (
    <ThemeProvider theme={customTheme}>
      <ThemeImage
        useBodyBackground
        alt="NAWAL AUTH IMAGE"
        baseName={selectedTheme.filename.replace(".png", "")}
      >
        <Helmet>
          <title>Welcome to Not All Who Wander Are Lost</title>
          <meta
            name="description"
            content="Transform your fitness journey into an epic quest. Sync with your Fitbit, choose custom routes—real or fictional—and track your progress as you explore new horizons."
          />
        </Helmet>
        <Grid
          style={{ minHeight: "100vh" }}
          paddingBottom={1}
          paddingTop={1}
          container
          justifyContent="center"
          alignItems="center"
        >
          <Grid flex={1} item xs={11} sm={8} md={6} lg={4}>
            <Card
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.9)",
              }}
            >
              <div
                style={{
                  textAlign: "right",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <Typography variant="body2" color="text.secondary">
                  Theme: {selectedTheme.theme}
                </Typography>
                <IconButton
                  onClick={() =>
                    setSelectedTheme(themes[Math.floor(Math.random() * themes.length)])
                  }
                >
                  <RefreshIcon />
                </IconButton>
              </div>
              <CardContent>
                <Stepper activeStep={activeStep} orientation="vertical">
                  <Step>
                    <StepLabel>Introduction and Agreement</StepLabel>
                    <Box sx={{ display: activeStep === 0 ? "block" : "none" }}>
                      <Typography variant="h6" component="p" gutterBottom>
                        Are Ready to embark on an unforgettable adventure?
                      </Typography>
                      <Typography variant="body1" component="p">
                        Whether you're walking through the Shire or cycling across futuristic
                        cityscapes, transform your fitness journey into an epic quest. Sync with
                        your Fitbit, choose custom routes—real or fictional—and track your progress
                        as you explore new horizons. Each milestone unlocks new experiences, keeping
                        you motivated and engaged. It’s more than fitness; it’s an adventure at your
                        own pace!
                      </Typography>
                      <Typography variant="body1" component="p" mt={2}>
                        <strong>Not All Who Wander Are Lost.</strong> Are you ready to wander?
                      </Typography>
                      <Typography variant="body2" color="text.secondary" mt={2}>
                        I agree to the <Link href="/terms">terms and conditions</Link> and have read
                        the <Link href="/privacy-policy">privacy policy</Link>.
                      </Typography>
                      <Button
                        variant="contained"
                        fullWidth
                        onClick={() => setIsTermsChecked(true)}
                        sx={{ marginTop: 2 }}
                      >
                        Agree
                      </Button>
                    </Box>
                  </Step>
                  <Step>
                    <StepLabel>Login with Email</StepLabel>
                    <Box sx={{ display: activeStep === 1 ? "block" : "none" }}>
                      {isEmailLinkSent ? (
                        <Typography variant="body2" color="text.secondary">
                          A login link has been sent to your email. Please check your inbox.
                        </Typography>
                      ) : (
                        <>
                          <Typography variant="body2" color="text.secondary">
                            Enter your email address below to receive a login link. Use the link to
                            log in to your account.
                          </Typography>
                          <TextField
                            label="Email"
                            variant="outlined"
                            fullWidth
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            sx={{ marginTop: 2 }}
                          />
                          <Button
                            fullWidth
                            variant="contained"
                            onClick={handleSendLoginLink}
                            disabled={isSendingEmail || isMeLoading}
                            sx={{ marginTop: 2 }}
                          >
                            {isSendingEmail ? "Processing..." : "Send Login Link"}
                          </Button>
                          {error && (
                            <Typography variant="body2" color="error" sx={{ marginTop: 2 }}>
                              {error}
                            </Typography>
                          )}
                        </>
                      )}
                    </Box>
                  </Step>
                  <Step>
                    <StepLabel>Connect with Fitbit</StepLabel>
                    <Box sx={{ display: activeStep === 2 ? "block" : "none" }}>
                      <Typography variant="body2" color="text.secondary">
                        To log in, please click the button below. This will redirect you to Fitbit's
                        authorization page where you can grant our app permission to access your
                        data.
                      </Typography>
                      <Button
                        variant="contained"
                        onClick={handleOAuthFlow}
                        disabled={isSendingEmail || isMeLoading}
                        sx={{ marginTop: 2 }}
                      >
                        {isSendingEmail ? "Processing..." : "Connect with Fitbit"}
                      </Button>
                      <Typography variant="body2" paddingTop={4}>
                        Want to log in with a different email?{" "}
                        <Link href="/api/v1/logout">Start Over</Link>
                      </Typography>
                    </Box>
                  </Step>
                </Stepper>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </ThemeImage>
    </ThemeProvider>
  );
};
export default Auth;
