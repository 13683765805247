import React from "react";
import { convertUnits } from "@/utils/distance-conversions";
import { useGetDistanceTypeQuery } from "@/store/api/settingsSlice";

interface DistanceTextProps {
  distanceInKm: number;
}

export const DistanceText: React.FC<DistanceTextProps> = ({ distanceInKm }) => {
  const { data: prefs, isLoading, error } = useGetDistanceTypeQuery();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error || !prefs) {
    return <div>Error loading user preferences</div>;
  }
  const distanceUnit = prefs.distanceType || "km";
  const distance =
    distanceUnit === "miles"
      ? convertUnits("mi", distanceInKm).toFixed(2)
      : distanceInKm.toFixed(2);

  const unit = distanceUnit === "miles" ? "mi" : "km";

  return (
    <>
      {distance} {unit}
    </>
  );
};
