import React, { ReactNode, useEffect, useState } from "react";

interface ThemeImageProps {
  baseName?: string;
  alt: string;
  children?: ReactNode;
  useBodyBackground?: boolean;
}
const checkFormatSupport = (format: string): Promise<boolean> => {
  if (format === "avif") {
    return new Promise<boolean>((resolve) => {
      const image = new Image();
      image.onerror = () => resolve(false);
      image.onload = () => resolve(true);
      image.src =
        "data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAB0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgQ0MAAAAABNjb2xybmNseAACAAIAAYAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACVtZGF0EgAKCBgANogQEAwgMg8f8D///8WfhwB8+ErK42A=";
    })
      .then(() => true)
      .catch(() => false);
  }
  const canvas = document.createElement("canvas");
  if (!!canvas.getContext && canvas.getContext("2d")) {
    const dataUrl = canvas.toDataURL(`image/${format}`);
    return Promise.resolve(dataUrl.indexOf(`data:image/${format}`) === 0);
  }
  return Promise.resolve(false);
};
const sizes = [480, 640, 800, 1024, 1600];
const resolutions = [1, 2];
const formats = ["avif", "webp", "jpeg"];
let format = "avif";
(async () => {
  for (const f of formats) {
    if (await checkFormatSupport(f)) {
      format = f;
      break;
    }
  }
})();
const ThemeImage: React.FC<ThemeImageProps> = ({
  baseName,
  alt,
  children,
  useBodyBackground = false,
}) => {
  const [backgroundImage, setBackgroundImage] = useState("");

  useEffect(() => {
    const updateBackgroundImage = () => {
      if (!baseName) return;

      const screenWidth = window.innerWidth;
      const pixelRatio = window.devicePixelRatio || 1;

      const closestSize = sizes.reduce((prev, curr) =>
        Math.abs(curr - screenWidth) < Math.abs(prev - screenWidth) ? curr : prev
      );
      const closestResolution = resolutions.reduce((prev, curr) =>
        Math.abs(curr - pixelRatio) < Math.abs(prev - pixelRatio) ? curr : prev
      );

      const selectedImage = `/images/generated/${baseName}-${closestSize}@${closestResolution}x.${format}`;
      setBackgroundImage(selectedImage);
    };

    updateBackgroundImage();
    window.addEventListener("resize", updateBackgroundImage);

    return () => {
      window.removeEventListener("resize", updateBackgroundImage);
    };
  }, [baseName]);

  if (useBodyBackground) {
    return (
      <>
        <div
          style={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            pointerEvents: "none",
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            // zIndex: 1,
          }}
          aria-label={alt}
        ></div>
        <div style={{ position: "relative" }}>{children}</div>
      </>
    );
  }
  return (
    <div style={{ minHeight: 250, flex: 1 }}>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 0,

          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          overflow: "scroll",
        }}
        aria-label={alt}
      >
        {children}
      </div>
    </div>
  );
};

export { ThemeImage };
