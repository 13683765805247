import { SuccessResponseType } from "@/schemas/responseTypes";
import { apiSlice } from "./apiSlice";

interface SendEmailLoginPayload {
  email: string;
}

export const authApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    removeFitbit: builder.mutation<SuccessResponseType, void>({
      query: () => ({ method: "PUT", url: "/auth/fitbit/remove" }),
      invalidatesTags: ["Me"],
    }),
    sendEmailLogin: builder.mutation<void, SendEmailLoginPayload>({
      query: (payload) => ({
        url: "/auth/send-email-login",
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const { useSendEmailLoginMutation, useRemoveFitbitMutation } = authApi;
