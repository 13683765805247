import { MeResponseType } from "@/schemas/responseTypes";
import { apiSlice } from "./apiSlice";

export const meApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMe: builder.query<MeResponseType, void>({
      providesTags: ["Me"],
      query: () => "/me",
      keepUnusedDataFor: 300, // 5 minutes
    }),
  }),
});

export const { useGetMeQuery } = meApi;
