import React from "react";
const __pages_import_0__ = React.lazy(() => import("/pages/[...all].tsx"));
import __pages_import_1__ from "/pages/index.tsx";
const __pages_import_2__ = React.lazy(() => import("/pages/privacy-policy.tsx"));
const __pages_import_3__ = React.lazy(() => import("/pages/settings.tsx"));
const __pages_import_4__ = React.lazy(() => import("/pages/terms.tsx"));
const __pages_import_5__ = React.lazy(() => import("/pages/routes/[id].tsx"));
const __pages_import_6__ = React.lazy(() => import("/pages/routes/index.tsx"));

const routes = [{"caseSensitive":false,"path":"*","element":React.createElement(__pages_import_0__)},{"caseSensitive":false,"path":"/","element":React.createElement(__pages_import_1__)},{"caseSensitive":false,"path":"privacy-policy","element":React.createElement(__pages_import_2__)},{"caseSensitive":false,"path":"settings","element":React.createElement(__pages_import_3__)},{"caseSensitive":false,"path":"terms","element":React.createElement(__pages_import_4__)},{"caseSensitive":false,"path":"routes","children":[{"caseSensitive":false,"path":":id","element":React.createElement(__pages_import_5__)},{"caseSensitive":false,"path":"","element":React.createElement(__pages_import_6__)}]}];

export default routes;