import React from "react";
import { Link } from "react-router-dom";
import { Typography, Box } from "@mui/material";
import { useRouteUserIsWanderingQuery } from "@/store/api";
import { RouteViewer } from "./routes/route-viewer";
import themes from "@/components/routes/themes.json";
interface DashboardProps {}

export const Dashboard: React.FC<DashboardProps> = () => {
  const { data, refetch } = useRouteUserIsWanderingQuery();
  React.useEffect(() => {
    const onFocus = () => {
      refetch();
    };
    window.addEventListener("focus", onFocus);
    return () => {
      window.removeEventListener("focus", onFocus);
    };
  }, [refetch]);
  if (data) {
    const theme = themes.find((t) => t.theme === data.theme);
    return (
      <RouteViewer
        useBodyBackground
        theme={theme?.theme}
        distanceTraveled={data.distanceTraveled}
        name={data.name}
        steps={data.steps}
        id={data.id}
      />
    );
  }
  return (
    <Box>
      <Typography variant="h5" component="div">
        Looks like you haven't picked a route yet.
      </Typography>
      <Typography variant="body2" color="textSecondary">
        Find one below, or <Link to="/routes">create your own</Link>.
      </Typography>
    </Box>
  );
};
