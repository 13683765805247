import { SuccessResponseType } from "@/schemas/responseTypes";
import { apiSlice } from "./apiSlice";
import {
  NewRoute,
  Route,
  RoutesWithoutSteps,
  RouteWithDistanceTraveledAndSteps,
  RouteWithWanderersAndDistance,
} from "@/schemas/route-schema";

export const routesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    routeUserIsWandering: builder.query<RouteWithDistanceTraveledAndSteps, void>({
      query: () => ({
        url: "/route/wandering",
      }),
      providesTags: ["RouteImWandering"],
    }),
    wanderRoute: builder.mutation<SuccessResponseType, string>({
      query: (routeId: string) => ({
        url: "/route/" + routeId + "/wander",
        method: "PUT",
      }),
      invalidatesTags: ["RouteImWandering", "MyFavoriteRoutes"],
    }),
    myFavoriteRoutes: builder.query<RouteWithWanderersAndDistance[], void>({
      providesTags: ["MyFavoriteRoutes"],
      query: () => "/route/favorites",
    }),
    favoriteRoute: builder.mutation<void, string>({
      query: (id) => ({
        url: `/route/${id}/favorite`,
        method: "PUT",
      }),
      invalidatesTags: ["MyFavoriteRoutes"],
    }),
    unfavoriteRoute: builder.mutation<void, string>({
      query: (id) => ({
        url: `/route/${id}/unfavorite`,
        method: "PUT",
      }),
      invalidatesTags: ["MyFavoriteRoutes"],
    }),
    publicRoutes: builder.query<
      { totalCount: number; routes: RouteWithWanderersAndDistance[] },
      { limit?: number; skip: number; sortBy?: string; theme?: string }
    >({
      providesTags: ["PublicRoutes"],
      query: ({ limit = 10, skip = 0, theme = "any", sortBy = "wanderers" }) => {
        return {
          url: "/routes",
          params: {
            limit,
            skip,
            theme,
            sortBy,
          },
        };
      },
    }),
    getMyRoutes: builder.query<RouteWithWanderersAndDistance[], void>({
      providesTags: ["MyRoutes"],
      query: () => "/me/routes",
    }),
    getRouteById: builder.query<RouteWithDistanceTraveledAndSteps, string>({
      query: (id) => `/route/${id}`,
      providesTags: (result) => [{ type: "Route", id: result?.id }],
    }),
    updateRoute: builder.mutation<Route, Route>({
      query: (route) => ({
        url: `/route/${route.id}`,
        method: "PUT",
        body: route,
      }),
      invalidatesTags: (result, error, route) => [
        "MyRoutes",
        { type: "Route", id: route.id },
        "RouteImWandering",
        "MyFavoriteRoutes",
      ],
    }),
    deleteRoute: builder.mutation<void, string>({
      query: (id) => ({
        url: `/route/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["MyRoutes", "MyFavoriteRoutes"],
    }),
    createRoute: builder.mutation<Route, NewRoute>({
      query: (route) => {
        if ("id" in route) {
          delete route.id;
        }
        return {
          url: "/route",
          method: "POST",
          body: route,
        };
      },
      invalidatesTags: (result, error, route) => [
        "MyRoutes",
        "RouteImWandering",
        "MyFavoriteRoutes",
      ],
    }),
  }),
});

export const {
  useGetMyRoutesQuery,
  useGetRouteByIdQuery,
  useUpdateRouteMutation,
  useDeleteRouteMutation,
  useCreateRouteMutation,
  usePublicRoutesQuery,
  useUnfavoriteRouteMutation,
  useFavoriteRouteMutation,
  useMyFavoriteRoutesQuery,
  useRouteUserIsWanderingQuery,
  useWanderRouteMutation,
} = routesApi;
