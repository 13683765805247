import { DistanceWithRoute, MyDistancesInput } from "@/schemas/distance-schema";
import { apiSlice } from "./apiSlice";

export const distanceApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    startingDistance: builder.query<{ startingDistance: number }, string>({
      query: (routeId) => `/distances/${routeId}/starting-distance`,
      providesTags(result, error, arg, meta) {
        return [{ type: "StartingDistance", id: arg }];
      },
    }),
    updateStartingDistance: builder.mutation<void, { routeId: string; startingDistance: number }>({
      query: ({ routeId, startingDistance }) => ({
        url: `/distances/${routeId}/starting-distance`,
        method: "PUT",
        body: { startingDistance },
      }),
      invalidatesTags: (result, error, { routeId }) => [
        { type: "StartingDistance", id: routeId },
        { type: "Route", id: routeId },
        "RouteImWandering",
      ],
    }),
    updateDistance: builder.mutation<void, { id: string; distance: number; route: string }>({
      query: ({ id, distance, route }) => ({
        url: `/distances/${id}`,
        method: "PUT",
        body: { id, distance, route },
      }),
      invalidatesTags: ["MyDistances", "RouteImWandering"],
    }),
    myDistances: builder.query<{ totalCount: number; rows: DistanceWithRoute[] }, MyDistancesInput>(
      {
        query: (input) => ({
          url: "/distances",
          params: input,
        }),
        providesTags: ["MyDistances"],
      }
    ),
  }),
});

export const {
  useMyDistancesQuery,
  useUpdateDistanceMutation,
  useStartingDistanceQuery,
  useUpdateStartingDistanceMutation,
} = distanceApi;
