import React from "react";
import { Box, Stepper, Step, StepLabel, Typography } from "@mui/material";
import Check from "@mui/icons-material/Check";
import Hiking from "@mui/icons-material/Hiking";
// milestone icon
import Map from "@mui/icons-material/Map";
import { RouteStepData as StepData } from "@/schemas/route-schema";
import { StepIconProps } from "@mui/material/StepIcon";
import { createTheme, styled, ThemeProvider, useTheme } from "@mui/material/styles"; // Ensure you're using MUI 5's styled utility
import { Link } from "react-router-dom";
import themes from "./themes.json";
import { ThemeImage } from "../ThemeImage";
import { DistanceText } from "../distanceText";
import { voidRouteId } from "@/constants";

// Custom Step Icon for the distance traveled marker
const DistanceMarkerIconRoot = styled("div")<{ ownerState: { active?: boolean } }>(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: "#fff",
  width: "24px",
  height: "24px",
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
}));

const MilestoneMarkerIconRoot = styled("div")<{
  ownerState: { active?: boolean; completed?: boolean };
}>(({ theme, ownerState: { completed } }) => ({
  backgroundColor: completed ? theme.palette.secondary.main : theme.palette.primary.main,
  color: "#fff",
  width: "24px",
  height: "24px",
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
}));

function DistanceMarkerIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <DistanceMarkerIconRoot ownerState={{ active }} className={className}>
      {completed ? <Check /> : <Hiking />}
    </DistanceMarkerIconRoot>
  );
}

function MilestoneIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <MilestoneMarkerIconRoot ownerState={{ active, completed }} className={className}>
      <Map />
    </MilestoneMarkerIconRoot>
  );
}

interface RouteViewerProps {
  onStepClick?: (step: StepData) => void;
  id?: string;
  name: string;
  steps: StepData[];
  distanceTraveled?: number;
  theme?: string;
  useBodyBackground?: boolean;
  children?: React.ReactNode;
}

const opacity = ".9";
const color = `rgba(255, 255, 255, ${opacity})`;
const routeTextStyle = {
  background: `linear-gradient(to right, ${color} 50%, transparent 80%)`,
  paddingTop: 2,
  paddingBottom: 2,
  paddingLeft: 8,
  maxWidth: 500,
};
const titleStyle = {
  textAlign: "right",
  color: "white",
  background: `linear-gradient(to left, rgba(0, 0, 0, .5) 70%, rgba(0,0,0,0) 90%)`,
  paddingRight: 6,
};
const RouteViewer: React.FC<RouteViewerProps> = ({
  steps,
  name,
  onStepClick,
  distanceTraveled = 1,
  theme,
  useBodyBackground,
  id,
  children,
}) => {
  const selectedTheme = themes.find((t) => t.theme === theme);
  const currentTheme = useTheme();
  const customTheme = createTheme({
    palette: {
      primary: {
        main: selectedTheme?.primaryColor || currentTheme.palette.primary.main, // Fallback to current theme if not found
      },
      secondary: {
        main: selectedTheme?.secondaryColor || currentTheme.palette.secondary.main, // Fallback to white if not found
      },
    },
  });

  const image = selectedTheme ? selectedTheme.filename : "";
  steps = steps.slice().sort((a, b) => a.distance - b.distance);
  const lastStep = steps[steps.length - 1];
  const completed = distanceTraveled && lastStep ? distanceTraveled >= lastStep.distance : false;
  let stepInserted = distanceTraveled === 0;
  const completedR = (
    <Step active completed={completed}>
      <StepLabel StepIconComponent={DistanceMarkerIcon} style={routeTextStyle}>
        <DistanceText distanceInKm={distanceTraveled} />
      </StepLabel>
    </Step>
  );
  const nextStep = steps.find((step) => step.distance > distanceTraveled);

  return (
    <ThemeProvider theme={customTheme}>
      <ThemeImage
        useBodyBackground={useBodyBackground}
        baseName={image.replace(".png", "")}
        alt={name}
      >
        {children}
        <Typography style={titleStyle as any} variant="h5">
          {name}
        </Typography>
        <Box sx={{ width: "100%" }}>
          <Stepper orientation="vertical">
            {steps.map((step, index) => {
              const stepR = (
                <Step
                  key={index}
                  completed={step.distance <= distanceTraveled}
                  sx={{
                    cursor: onStepClick ? "pointer !important" : "inherit",
                  }}
                  onClick={() => {
                    if (onStepClick) {
                      onStepClick(step);
                    }
                  }}
                >
                  <StepLabel
                    sx={{
                      cursor: onStepClick ? "pointer !important" : "inherit",
                    }}
                    StepIconComponent={MilestoneIcon}
                    style={routeTextStyle}
                  >
                    <DistanceText distanceInKm={step.distance} />
                    <br />
                    {step.name}
                  </StepLabel>
                </Step>
              );
              if (stepInserted) return stepR;
              if (step.distance >= distanceTraveled) {
                stepInserted = true;
                if (step.distance === distanceTraveled) {
                  return (
                    <React.Fragment key={index}>
                      {stepR}
                      {completedR}
                    </React.Fragment>
                  );
                }
                return (
                  <React.Fragment key={index}>
                    {completedR}
                    {stepR}
                  </React.Fragment>
                );
              }
              return stepR;
            })}

            {!stepInserted && completedR}
          </Stepper>
          <div style={titleStyle as any}>
            {completed && (
              <Typography color="white" variant="h6">
                You've wandered beyond the route.
                <br />
                <Link
                  to="/routes?tab=exploreRoutes"
                  style={{
                    color: "white",
                    textDecoration: "underline",
                  }}
                >
                  Pick a new one to continue wandering.
                </Link>
              </Typography>
            )}
            {!completed && lastStep && id !== voidRouteId && (
              <Typography color="white" variant="h6">
                Keep Going!{" "}
                {nextStep && nextStep !== lastStep ? (
                  <>
                    Only <DistanceText distanceInKm={nextStep.distance - distanceTraveled} /> to{" "}
                    {nextStep.name} and{" "}
                  </>
                ) : (
                  ""
                )}
                <DistanceText distanceInKm={lastStep.distance - distanceTraveled} /> to{" "}
                {lastStep.name}
              </Typography>
            )}
            {id === voidRouteId && (
              <>
                <br />
                <Typography color="white" variant="h6">
                  You are traveling deeper into the void. This is where those who have not selected
                  a route will wander.
                  <br />
                  <Link
                    to="/routes?tab=exploreRoutes"
                    style={{
                      color: "white",
                      textDecoration: "underline",
                    }}
                  >
                    Pick a new one to continue wandering.
                  </Link>
                </Typography>
              </>
            )}
          </div>
        </Box>
      </ThemeImage>
    </ThemeProvider>
  );
};

export { RouteViewer };
