import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/v1",
    headers: { "Content-Type": "application/json" },
  }),
  tagTypes: [
    "Route",
    "MyRoutes",
    "Me",
    "PublicRoutes",
    "MyFavoriteRoutes",
    "RouteImWandering",
    "MyDistances",
    "Settings-distance",
    "StartingDistance",
    "Settings-timezone",
  ],
  endpoints: (builder) => ({}),
});
