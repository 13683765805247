import { useGetTimeZoneQuery, useSetTimezoneMutation } from "@/store/api/settingsSlice";
import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";

const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const DecectTimezoneChange = () => {
  const { data, refetch, isLoading } = useGetTimeZoneQuery();
  const [updateTimezone] = useSetTimezoneMutation();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (data?.browserTimezone && data?.browserTimezone !== browserTimezone) {
      setOpen(true);
    }
    if (!isLoading && !data?.timezone) {
      updateTimezone({
        browserTimezone: browserTimezone,
        timezone: browserTimezone,
      });
    }
  }, [data]);

  const handleNo = () => {
    setOpen(false);
    updateTimezone({ timezone: data?.timezone ?? browserTimezone, browserTimezone });
  };
  React.useEffect(() => {
    const onFocus = () => {
      refetch();
    };
    window.addEventListener("focus", onFocus);
    return () => {
      window.removeEventListener("focus", onFocus);
    };
  }, [refetch]);

  const handleUpdateTimezone = () => {
    updateTimezone({ timezone: browserTimezone, browserTimezone });
    setOpen(false);
  };

  if (isLoading) {
    return null;
  }

  return (
    <Dialog open={open} onClose={handleNo}>
      <DialogTitle>Timezone Change Detected</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Your browser timezone has changed from {data?.browserTimezone} to {browserTimezone}. Would
          you like to update your settings?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleNo} color="primary">
          Cancel
        </Button>
        <Button variant="contained" onClick={handleUpdateTimezone} color="primary" autoFocus>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { DecectTimezoneChange };
